import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout'
import ModalImage from 'react-modal-image-responsive'
import './citi-velocity.css'
import Tile from '../components/tile/tile'
import CVCompanySignalOverview from '../images/CitiVelocity/Company Signal Overview.png'
import CVCompanySignalMarkets from '../images/CitiVelocity/Company Signal Markets.png'
import CVChartingCurve from '../images/CitiVelocity/Charting/Curve With Regression.png'
import CVContentGroup from '../images/CitiVelocity/Content Group.png'
import CVOldMarketBuzz from '../images/CitiVelocity/Old Market Buzz.png'
import ICGDSButtonsDark from '../images/ICGDS/ICGDS Button.png'
import ICGDSButtonsLight from '../images/ICGDS/ICGDS Button Light.png'


export default function CitiVelocity() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <title>Leland Riordan - Portfolio - Citi Velocity</title>
      </Helmet>
      <div className="case-study-feature">
        <img src={CVCompanySignalMarkets} className="case-study-feature-image" alt='Citi Velocity'/>
      </div>

      <div className="main-content case-study-content">
        <div className="row row-max">
          <h1 className="case-study-title">Citi Velocity</h1>
          <p className="lead">
            Citi Velocity is the #1 ranked web analytics platform on Wall St. and the face of Citi's $20+ billion markets, trading, and investment banking businesses.
          </p>
          <p className="lead">
            In my role as Head of UX and Product Design, I led a ground up redesign of the Citi Velocity platform while revamping its information architecture and building out the design team, system and process.
          </p>

          {/* <div className="table-of-contents">
            <h4>Table Of Contents</h4>
            <ul>
              <li><a href="#introduction">Introduction</a></li>
              <li><a href="">My Role</a></li>
              <li><a href="">User Research & Analytics</a></li>
              <li>
                <a href="">Information Architecture</a>
                <ul>
                  <li><a href="">Signal Pages</a></li>
                  <li><a href="">Integrate, Update Or Retire</a></li>
                  <li><a href="">New Navigation</a></li>
                </ul>
              </li>
              <li><a href="">Analytics & Content</a></li>
              <li><a href="">Conclusion</a></li>
            </ul>
          </div> */}
        </div>

        <div className="row row-alt">
          <h2 className="text-centered">A Content & Analytics Platform With Global Scale</h2>
          <div className="tiles">
            <Tile
              title="25 Million+"
              label="Chartable Data Tags">
            </Tile>

            <Tile
              title="10,000+"
              label="Videos, Publications & Events Published Per Month">
            </Tile>

            <Tile
              title="1500+"
              label="Applications & Tools">
            </Tile>
          </div>
        </div>

        <div id="introduction" className="row row-max">
          <h2>Introduction</h2>
          <p>
            Citi Velocity is the web, mobile and desktop home for 500,000+ of Citi's most valuable clients across markets, trading and investment banking.
            The platform is a content and analytics juggernaught providing users access to 10,000+ exclusive videos, publications and events per month, an award winning charting suite with 25+ million tags, and more than 1500+ applications.
          </p>
          <p>
            However, after nine years of growth without a centralized design team, standards or process, the user experience varied dramatically between apps and pages.
            Some apps featured industry-leading analytics and tools unrivaled on the street, while others were stuck in 1995 with cramped unreadable layouts designed to squish as much content above the fold as possible. 
            Worse yet, no thought had been given to information architecture forcing users to navigate the platform via a confusing mega navigation menu with hundreds of poorly categorized links.
          </p>
        </div>

        <div className="figure-container">
          <figure>
            <figcaption>Old Citi Velocity Homepage Circa 2019</figcaption>
            <ModalImage
              small={CVOldMarketBuzz}
              large={CVOldMarketBuzz}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Old Citi Velocity Homepage Circa 2019"
            />
          </figure>
        </div>

        <div className="row row-max">
          <h2>My Role</h2>
          <p>
            I was hired as the Head of Product & UX Design transform this loose collection of content and apps into a true application platform.
            Over the next two years I led a complete redesign of Citi Velocity, overhauled its information architecture and helped craft its long term product strategy and vision.
            During this period, I grew the Citi Velocity design organization from 3 to 12+ members divided in two teams and helped launch ICG Design System across Citigroup.
          </p>
        </div>

        <div className="row row-max">
          <h2>User Research</h2>
          <p>
            Until 2019, Citi Velocity product prioritization was done ad hoc and primarily based upon the whims and desires of the C-suite.
            I partnered with the COO and Head of Data Science to implement a data driven approach.
          </p>
          <p>
            On the qualitative side, I started a user research program utilizing my seat on the trading floor to conduct both formal and impromptu tests.
            I worked with the support team to create a formal feedback loop process so that we could quickly react to the most common complaints and feedback.
            Next, I worked with the head of marketing to buy a Qualtrics license so that we could start collecting targeted user surveys and feedback.
          </p>
          <p>
            On the quantitative side, we established clear KPIs, OKRs and product strategy metrics and then had the developers create an analytics dashboard to track them.
            While we tracked many of these metrics, we focused on three with regard to the redesign:
          </p>
          <dl>
            <dt>Stickiness (Start: 68%, Goal: 75%)</dt>
            <dd>How likely a user would return to the platform over a given timeframe. Tracked at the platform level but could be drilled into by user type, content type, etc.</dd>

            <dt>Full Screen Time Retention (Start: 7.4%, Goal: 8%)</dt>
            <dd>Percentage of users who kept Citi Velocity open all day on one of their trading monitors. These were the power users on the platform and drove the most trading revenue.</dd>

            <dt>Net Promoter Score (Start: 7.2, Goal: 7.9%)</dt>
            <dd>How likely a user would recommend the platform to a friend or colleague.</dd>
          </dl>
        </div>

        <div className="figure-container row-new">
          <figure className="column">
            <figcaption>ICG DS Dark Theme Buttons</figcaption>
            <ModalImage
              small={ICGDSButtonsDark}
              large={ICGDSButtonsDark}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="ICG DS Dark Theme buttons"
            />
          </figure>

          <figure className="column">
          <figcaption>ICG DS Light Theme Buttons</figcaption>
            <ModalImage
              small={ICGDSButtonsLight}
              large={ICGDSButtonsLight}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="ICGDS Light Theme Buttons"
            />
          </figure>
        </div>

        <div className="row row-max">
          <h2>Design System</h2>
          <p>
            When I joined the firm, Citi Velocity looked like what it was, a patchwork quilt of mismatched applications and content from different businesses within Citi.
            Hundreds of different teams develop applications on the platform, each with its own stakeholders, timelines, tech stacks and goals.
            Unifying these disperate groups would be daunting - the only way to start was from the ground up with a standard design system.
          </p>
          <p>
            Upon my hiring, I inherited a small React library that had been rolled out to the core analytics pages on Citi Velocity called Elemental.
            I immediately had my team get to work on rapidly turning it into a true design system.
            I assigned my top lieutenant to be its leader and together we rapidly scaffolded out a prototype.
            I leveraged my location on the trading floor to gather instant user feedback as we went.
          </p>
          <p>
            Soon we had an MVP library of components and demo pages built in Sketch that I used to gather buy-in from the other design leaders at the firm.
            One of those leaders, the Head of Design for Global Spread Products, was working on a similar project.
            We combined our resources, changed the name to ICG Design System and successfully pitched the executive team for funding.
            Soon we had developer teams building Angular and React libraries of the components.
            Fast forward to now and ICG Design System has been set as the standard DS for all products at the firm.
            It has grown to include support dark and light themes as well as third party libraries such as AG Grid and High Charts.
            We hope to open source the system in the future if we can get passed the myriad of hurdles from the legal team.
          </p>

          {/* <h2>Process</h2>
          <p>
            There was no standard model for designing and developing applications on the Citi Velocity platform.
            Some apps were made by a single unicorn developer without outside input.
            Others were developed by teams with hundreds of developers following agile best practices.
            Apps and features on the platform were greenlight and then 
          </p>
          <p>
            When I was hired, the Citi Velocity UX team consisted of only two mid level designers who's primary responsibility was to work on marketing iniatives.
            I was tasked with rapidly expanding the team and figuring out a scalable team structure that would be a solid foundation for the future.
            Since hiring was slow, competitive and expensive in New York, I expanded the team's footprint to major Citi offices in Toronto and Shanghai.
            I would keep high level employees in the NY headquarters but spread entry level individual contributors to these satellite offices.
            I hired the famed design agency RGA to help ramp up strategic projects while we filled internal roles.
            In terms of team structure, I surveyed the book of work and decided to split split the work into two teams - the Core Content team and the Analytics team.
            The Core Content team would work on application wide features and 
          </p>

          <p>
            In order to support all of these iniatives I needed to expand my team rapidly.
            Hiring competition was tough in New York so I started by hiring RGA to help me with the redesign.
            While that agency engagement was happening, I opened roles in Toronto, Shanghai
          </p> */}
        </div>

        <div className="row row-max">
          <h2>Information Architecture</h2>
          <p>
            Citi Velocity's information architecture was a mess.
            Users were forced to navigate the platform via a 1,500+ item mega navigation menu. 
            The dropdowns arranged pages in a confusing hierarchy based upon Citi business unit and were polluted with hundreds of ancient apps with limited usage.
            Usage data showed that the average user used nine apps or pages regularly and user testing proved that they had no idea how to find them buried on the platform.
          </p>
          <p>
            Frustrated users would often turn to search to find their desired content but that experience was even worse.
            The search results page presented the user with way too much content.
            Further, the search results page made it seem as though it was showing you everything but it didn't index critical items like company research.       
            Simple searches like the name of a page or an exact publication title would often turn up empty.
          </p>

          <p>To improve this experience we came up with a multi-pronged strategy that included redesigning the navigation, introducing signal pages and implementing an integrate, upgrade or retire strategy.</p>
        </div>

        <div className="figure-container">
          <figure>
            <figcaption>Company Signal Page</figcaption>
            <ModalImage
              small={CVCompanySignalOverview}
              large={CVCompanySignalOverview}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Citi Velocity Company Signal Page"
            />
          </figure>
        </div>
        <div className="row row-max">
          <h4>Signal Pages</h4>
          <p>
            Content about a given topic was spread through many different pages and tools.
            For example, if a user wanted to research Google stock, they would need to visit upwards of 10 different apps on the platform.
            I pitched the idea for signal aka taxonomy pages to better organize content, reduce the number of menu items and give an anchor to the navigation experience.
            The signal pages would act as hubs, aggregating all content and analytics from across the platform into one place.
            Users can now sign into the platform, type a company name and instantly get all of Citi's content about the company.
          </p>

          <h4>Integrate, Update Or Retire</h4>
          <p>
            The introduction of signal pages made a lot of the small applications and pages redundant and obsolete.
            Velocity's COO and I set a goal to update or retire 60+% of these pages over the next few years.
            We met with each business and took a holistic look at their pages and content.
            We would then make a decision on whether to update the app, integrate the content into a signal page or retire it permanently.
            With this approach we have been able to dramatically reduce the number of menu items and significantly improve discovery on the platform.
          </p>

          <h4>New Navigation</h4>
          <p>
            After many rounds of iteration, A/B and user testing we landed upon a new global navigation design that arranged the navigation around the users workflow.
            We placed Recent, Favorites, Analytics and Suggested as top level menu items allowing users to jump straight to the content they used the most frequently.
            The aptly named Browse hamburger button triggered a flyout panel that arranged the remaining menu items in a logical and easy to understand manner.
            (Note: User testing showed that our older, less technical users had trouble when just using an icon so we added the "Browse" label).
          </p>
        </div>
        
        <div className="figure-container">
          <figure>
            <figcaption>Citi Velocity Charting 3.0 Curve With Regression Creation Page</figcaption>
            <ModalImage
              small={CVChartingCurve}
              large={CVChartingCurve}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Citi Velocity Charting 3.0 Curve With Regression Creation Page"
            />
          </figure>
        </div>

        <div className="row row-max">
          <h2>Analytics</h2>
          <p>
            Backed by 25+ million live data points, Citi Velocity's Charting 3.0 suite is the most powerful web based charting app on Wall Street and the most used application on Velocity.
            The suite was incredibly sticky and a key revenue driver, but user research showed that a substantial portion of users had no idea how to find the tools let alone use them.
          </p>
          <p>
            There was also the problem of technical debt and scope creep.
            The charting platform had been custom built over 10 years without refinement.
            Due to the size and scale of the platform, we needed to be careful to not make large changes that would frustrate power users.
            I had my Analytics design team get to work on incremental improvements to the tool to slowly redesign it over time, prioritizing quick usability wins.
            We broke the app up into micro experiences (ie, data selection, chart settings & configuration) and refined each piece over time.
          </p>

          <h4>Content Groups</h4>

          <p>
            While my Analytics design team focused on the refinement of the core suite, I was tasked by the CEO with figuring out how to fix the Charting suite's discovery problem.
            I came up with the idea of content groups:
          </p>

          <blockquote>
            What if we...<br/>
            Pair Citi Velocity's exclusive written and video content with our award winning data and analytics to give users a 360 view into what's really moving the markets?
          </blockquote>

          <p>
            When a big event occurs like the Federal Reserve increases rates or the US Capitol is stormed (&#9785;), creators on the platform would author content and produce videos to analyze the market reaction.
            We could pair this content with small charts or data tiles to give our users more context about the event or topic.
            This worked great from both a user experience perspective and a monetization strategy perspective.
            The analytics would enhance the content and navigation experiences while also functioning as a deep link into the full charting and analytics suite of apps.
          </p>
        </div>
        <div className="figure-container">
          <figure>
            <figcaption>
              Content Group Example. <br/>
              In this pairing, content from a top author is paired with a corresponding credit market chart.
            </figcaption>
            {/* <img src={CVImage4} className="case-study-image" alt='Citi Velocity'/> */}
            <ModalImage
              small={CVContentGroup}
              large={CVContentGroup}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Content Group Example"
            />
          </figure>
        </div>

        <div className="row row-max">
          <h2>Outcome</h2>
          <p>
            We took a careful measured approach to the rollout of the Citi Velocity redesign by releasing it in phases and permissioning groups of users over time with feature flags.
            At each step we analyzed usage analytics, gathered user feedback and made final refinements.
            The first phase was fully released to clients in summer of 2021 followed closely by phase 2 in January 2022.
          </p>
          <p>
            The redesign was a resounding success.
            We beat almost all of our target metrics including +25% stickiness growth while driving our net promoter score to 8+.
            Our incremental enhancements to charting improved its discoverability and we won three straight Best Analytics Platform on Wall Awards from Institutional Investor magazine.
          </p>
          <p>
            I am proud of what my team and I have been able to accomplish given our limited resources, the mountain of corporate bureaucracy we had to conquer and the fact that we did it all while working remotely during the height of the COVID-19 pandemic.
          </p>
          {/* <p>
            The one thing I would do differently in the future is take harder stands and compromise less. While the redesign exceeded all of my initial expectations I feel as we made a few too many compromises for arbirtr
          </p> */}

          {/* <p>
            In retrospect, the main thing that I would have done differently is redesign the mobile app experience in concert with the web version.
            While historically the mobile version of Citi Velocity was only used by a subset of users, the COVID-19 pandemic caused usage to grow by 100% almost overnight.
            While we were able to update the styling and a few key elements, we just didnt have the resources to do both redesigns at the same time or to change priorities midway through the redesign process.
            Redesigning the mobile experience has been made a top priority for 2022 and my team and I have been working on early protoypes.
          </p> */}

        </div>
      </div>

    </Layout>
  )
}
